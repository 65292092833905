<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-12">
        <input-base
          rules="required"
          label="Nombre"
          maxlength="150"
          placeholder="Título del informe"
          v-model="form.nombre"
          focus
        />
      </div>
      <div class="column is-12">
        <input-base
          rules=""
          label="Descripción"
          maxlength="250"
          placeholder="Descripción del informe"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <p class="is-pulled-right is-italic" v-if="form.updated_at">
      <span class="has-text-weight-semibold">Actualizado:</span> {{form.updated_at}}
    </p>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="() => $emit(`oncancel`)"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    form:{
      get(){
        return this.value ?? {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`form_1617206897966/save`, this.form)
      if(row){
        const status = this.form.id ? `editar` : `crear`
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de ${status} el informe: "${row.nombre}"`
        );
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>

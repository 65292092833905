<template>
  <div>
    <title-bar
      :items="[
        { title: 'Informes', to: `/form/1617206897966` },
        { title: title_page },
      ]"
    >
    </title-bar>
    <div class="box">
      <Form 
        v-model="row"
        @oncancel="() => $router.back()"
        @onsuccess="() => $router.back()" />
    </div>
  </div>
</template>

<script>
import Form from "../components/nuevo/Form";
export default {
  components: {
    Form,
  },
  data(){
    return {
      row: {}
    }
  },
  computed:{
    name_store(){
      return `form_1617206897966`
    },
    title_page(){
      const {id} = this.$route.query
      return id ? `Editar` : `Nuevo`
    }
  },
  async created(){
    const {id} = this.$route.query
    if(id){
      const data = await this.$store.dispatch(`${this.name_store}/get`, id)
      if(data){
        this.setForm(data)
      } else {
        this.$store.dispatch("app/set404", true)
      }
    } else {
      this.setForm()
    }
  },
  methods: {
    setForm(data = {}){
      this.$set(this,'row',{
        id: data.id ?? null,
        nombre: data.nombre ?? '',
        descripcion: data.descripcion ?? '',
        updated_at: data.updated_at ?? null,
      })
    }
  }
};
</script>